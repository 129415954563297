.App {
  height: 100%;
  width: 100%;
  max-width: 760px;
  max-height: 480px;
  
}

.App2{
  height: 100%;
  width: 100%;
  max-width: 760px;
  max-height: 480px;
  display:flex;
  flex-wrap:wrap;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  height:100%; 
  width:100%; 
  margin:auto 0; 
  padding:0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 8pt;
  background-color: #000;
  background-position: top;
  background-size: 100vw 50vh;
  background-repeat: no-repeat;
  color: #fff;
  background-image: url(background10.gif);
}

.App-link {
  color: #61dafb;
}

select {
  
  font-size: 8pt;
  border: 1px solid #fff;
  background-color: #000;
  padding: 10px;
  color: #ccc;
}

canvas {
  aspect-ratio: 1.625;
}

.mainContainer{
  height:100%;
  width:100%;

}

.title{
  width: 760px;
  margin: 30px auto;
  text-align: center;
}

#version {
  text-align: center;
}