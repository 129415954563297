.footer{
    border: 2px solid #013; 
    padding: 30px; 
    height: 100%;
    width: 100%;
    max-width: 696px;
    max-height: 154px;
    margin: 0 auto;
}

.footerText{
    width: 380px;
    float: left;
}

.footerText2{
    text-align: center;
    margin: 0px 0px 0px 0px;
    float: right;
    width: 300px;
}

p, h2{
    margin: 0; 
    padding: 0; 
}

.container{
    margin: 0 auto;
    max-width: 760px;
    max-height: 154px;
}
